<div class="container-fluid">
   <div class="row">
       <div class="col-12" style="text-align: center;color:white;margin-top:2rem;">
        <img style="margin-top:3rem" src="assets/img/logo-aden.png" alt="">
        <br/>
        <br/>
           <h2>¡BIENVENIDOS!</h2>
           <h1 class="equipo-inicio" style="border-top: 2px solid #ffffffc4;">ADVENTURER MISSION</h1>
       </div>
   </div>
   <div class="row" style="text-align: center;margin-top:3rem;bottom:0">
    <div class="col-4" style="padding: 1rem;margin-left:auto;margin-right: auto;">
      <button class="btn-comun" [routerLink]="['/instrucciones']" >
        <h4>CONTINUAR</h4>
     </button>
    </div>
   </div>

   </div>
   