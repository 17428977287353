import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Location } from '@angular/common';


@Component({
  selector: 'app-root',
  // template: '<h1>HOLA {{message}}</h1><br><app-header (messageEvent)="receiveMessage($event)"></app-header>',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent {
  message: any;
  receiveMessage($event){
    
    this.message = $event
  }
  
  toggle:boolean = true;


  change(){
    this.toggle = !this.toggle;
  }
  
  // agreed = 0;
  // messageToSendP: string = '';
  // receivedChildMessage: string;
  // voters = ['Narco', 'Celeritas', 'Bombasto'];
  // @Input() receivedParentMessage: string;
  // onVoted(agreed: boolean) {
  //   console.log('llego');
  // }

  // childData:string;
  // parentMethod(data){
  //   this.childData= data;
  // }
  // getMessage(message: string) {
  //   console.log('Llego el mensaje');
  //   this.receivedChildMessage = message;
  // }
    
}
