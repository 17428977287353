import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { Component } from '@angular/core';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css'],
})
export class InicioComponent {
  nombresimulador = 'ADEN Adventurer Mission | ';
}