<div class="container-fluid">
<div class="row">
	<div class="col-12 contenido">
	<div class="row">
		<div class="col-10 contenedor-consigna load-left2right-1">
			<div class="row">
				<div class="col 10 progreso"></div>
			</div>
			<div class="row segundo">
				<div class="col-10" style="margin-left: auto;margin-right:auto;">
					<h3 class="titulo">Bienvenidos al Simulador ADVENTURER MISSION</h3>
                    <!-- <p class="texto-interno">Estas instrucciones son específicamente para este equipo. Ustedes son los encargados de dar a conocer la información.</p>
					<p class="texto-interno">La mejor negociación obtenida con el resto de los equipos les permitirá obtener el logro final de la misión.</p>
					<p class="texto-interno" style="border-radius: 5px;border:1px solid #000;padding:5px;margin:10px 0px">
						<strong>DEBEN RECORDAR ESTA CONSIGNA:</strong> Frente a cualquier negociación ustedes no pueden hablar con nadie, sólo por ESCRITURA. Otros buscarán hacerlos hablar.
					</p>
					<p class="texto-interno">Las consignas requieren toma de decisión diferentes.</p> -->
					<p class="texto-interno">En este juego ustedes formarán equipos al azar. Ocuparán roles en esos equipos y tomaran decisiones de acuerdo al rol. En algun momento deberán trabajar en las salas pequeñas. A las que deberán llamar por el nombre que les toque al grupo.</p>
					<p class="texto-interno">En otros momentos deberán pasar al la sala grande. Uno de ustedes compartira pantalla en la sala pequeña. No puede ser el Contador del equipo. Otro de ustedes deberá usar el celular y alguna app para leer códigos.</p>
					<p class="texto-interno">Todo dependerá de las decisiones que tomen. Estas pueden ser:</p>
				</div>
				<div class="col-12 completar">
					<div class="row">
						<div class="col-4">
							<h5 class="titulo">DECISIONES <br/>DE ADAPTACIÓN</h5>
							<p class="texto-interno">Las decisiones de adaptación se refieren a las elecciones que se hacen ante una combinación de factores que se salen un poco de lo habitual y que tratan de abordar situaciones emergentes. De allí que implican una mejora o modificación de las rutinas habituales.</p>
						</div>
						<div class="col-4">
							<h5 class="titulo">DECISIONES <br/>INNOVADORAS</h5>
							<p class="texto-interno">Las decisiones innovadoras se basan en el descubrimiento, la identificación y el diagnóstico de problemas inusuales y ambiguos y/o el desarrollo de soluciones alternativas únicas o creativas. Por su naturaleza, estas decisiones entrañan una ruptura con el pasado y casi nunca se presentan en secuencia lógica y ordenada.</p>
						</div>
						<div class="col-4">
							<h5 class="titulo">DECISIONES <br/>SITUACIONALES</h5>
							<p class="texto-interno">Normalmente, se basan en información que cambia a gran velocidad. Es más, tal vez se tomen antes de que los problemas hayan sido definidos y entendidos a fondo. Por lo tanto, para que las personas tomen decisiones efectivas deben poner especial atención en definir correctamente los problemas y en reconocer que las primeras acciones afectan de forma ostensible las decisiones posteriores.</p>
						</div>
					</div>
				</div>
				<div class="col-10 completar">
					<p class="texto-interno">Deben repartirse 4 cargos principales: Uno de ustedes ocupará el rol del <strong>director logístico</strong>. Éste será el encargado de darle respuesta a las consignas que incluyan cálculos precisos. Su formación académica pasa por el área de las matemáticas y la ingeniería. Sus estudios y su experiencia lo han posicionado como quien manteniendo la mente fría puede tomar decisiones importantes en momentos claves de la misión, sin dejarse llevar por lo emocional.</p>
					<p class="texto-interno">Otro de ustedes será el <strong>director de operaciones</strong>. Éste será el encargado de manejar las consignas que hablen de ubicaciones y planimetría. Sus estudios del terreno y su experiencia en situaciones extremas le posibilitarán accionar sin tener que consultar ni consensuar con el resto del equipo.</p>
					<p class="texto-interno">El tercer puesto será el de <strong>Director General</strong>. Este será el encargado de tomar las decisiones que incluyan lo emocional y el cuidado del equipo. Su formación de grado pasa por los Recursos Humanos y el liderazgo.</p>
					<p class="texto-interno">Si existe una cuarta persona deberá encargarse de completar la bitácora y manejar el dinero que vayan encontrando para poder regularlo hasta alcanzar la misión. De no existir una cuarta persona este cargo caerá en el Director de Operaciones.</p>
					<p class="texto-interno">No se precipiten en las decisiones, escuchen todas las opiniones, revean antes de enviar</p>
					<p class="texto-interno">la respuesta solicitada, y vuelvanse a plantear la posibilidad de estar errados.</p>
					<p class="texto-interno">No corremos contra el tiempo.</p> <h2 class="titulo">REPETIMOS: No es una carrera.</h2>
				</div>
				<div class="col-10 completar">
					<p class="texto-interno">Para lograr completar la misión deben trabajar en equipo, obteniendo lo mejor de cada uno y sumado al resto de los integrantes. Les daremos 10 minutos para que se presenten y den cuenta de sus mejores habilidades. Luego de ello deberán repartirse los cargos de acuerdo a esas habilidades. Cada uno tendrá que resolver consignas diferentes. Esperamos de ustedes lealtad, trabajo en equipo y entrega a la causa.</p>
					<p class="texto-interno">Una vez repartidos, les pedimos que se identifiquen renombrando su imagen en ZOOM (Director General - Nombre)</p>
					<p class="texto-interno">Recién despues de eso podrán iniciar el recorrido.</p>
				</div>
			</div>
		</div>
		<button class="col-10 btn-iniciar load-left2right-1"  routerLink="/juegoderoles">CONTINUAR</button>
	</div>
	</div>
</div>
</div>

