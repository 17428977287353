<div class="container-fluid">
  <!-- <h3>A continuación te presentamos la plataforma con sus diferentes funcionalidades</h3>
  <a href="#carouselExampleIndicators" role="button" data-slide="next">
    <span>SIGUIENTE</span>
  </a> -->
<div class="row">
  <div class="col-8" style="margin-left: auto;margin-right:auto; padding: 1rem;border:1px solid #fff;border-radius: 5px;color:#fff;text-align: center;font-size: 13pt;margin-top:1rem;margin-bottom:1rem;">
    <p>A continuación te mostramos cómo está conformada la plataforma, su interfaz.
      Una vez que hayan finalizado, deberán aguardar por el nuevo link para su equipo.
    </p>
  </div>
</div>
  <div class="col-12" style="margin-left:auto;margin-right:auto;">
    <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
      <ol class="carousel-indicators">
        <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
        <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
      </ol>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img class="d-block w-100" src="assets/img/recorrido/r-0.jpg" alt="0">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="assets/img/recorrido/r-1.jpg" alt="1">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="assets/img/recorrido/r-2.jpg" alt="2">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="assets/img/recorrido/r-3.jpg" alt="3">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="assets/img/recorrido/r-4.jpg" alt="4">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="assets/img/recorrido/r-5.jpg" alt="5">
        </div>
        <div class="carousel-item">
          <img class="d-block w-100" src="assets/img/recorrido/r-6.jpg" alt="6">
        </div>
      </div>
      <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Anterior</span>
      </a>
      <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Siguiente</span>
      </a>
    </div>
  </div>

  <div class="col-8" style="margin-left: auto; margin-right: auto;background: #ff0;border-radius: 7px;margin-top: 1rem;text-align: center;">
    <h2>Aguarden por instrucciones.</h2>
  </div>
</div>