<div class="container-fluid">
    <div class="row">
        <div class="col-12" style="text-align: center;color:white;margin-top:2rem;">
         <img style="margin-top:3rem" src="assets/img/logo-aden.png" alt="">
         <br/>
         <br/>
            <h3>ADVENTURER MISSION</h3>
            <h1 class="equipo-inicio" style="border-top: 2px solid #ffffffc4;">EQUIPO {{nombregrupo}}</h1><br/>
        </div>
    </div>
   <div class="row" style="text-align: center;margin-top:3rem;bottom:0">
    <div class="col-8 texto" style="padding: 1rem 3rem;margin-right: auto;margin-left:auto;">
        <h4>¿Qué es un juego de rol?</h4>
        <p>El juego de rol es una actividad que se realiza principalmente con la imaginación. Es completamente interpretativo, es decir, todos los participantes deben meterse en el papel de un personaje descripto y ficticio con habilidades similares o no, a las que usted posee. Por ejemplo, en este juego “Adventurer Mission” en particular se requiere que los participantes interpreten 4 roles específicos dentro del grupo de tareas que tendrá asignada una misión. La dinámica de los juegos de rol es muy similar a la de los principales juegos de la infancia como “policías y ladrones”, “papá y mamá” o “indios y vaqueros”, sólo que en el rol se elaboran más las características específicas de los personajes. </p>
        <p>Todas esas características estarán detalladas en las hojas del personaje.</p>
        <p>¿Qué es una hoja de personaje? La hoja de personaje contiene toda la información referente a las características del avatar de un jugador, incluyendo habilidades mentales y físicas, así como descripción del personaje, puntuación y notas de importancia. La hoja de personaje varía de juego a juego. </p>
        <h4>CONSTRUCCIÓN DE PERSONAJES</h4>
        <p>Los personajes son la base del juego, ellos son los avatares de los jugadores en el mundo imaginario donde se desarrollan las acciones. Antes que cualquier otra actividad, cada participante de la sesión debe crear su propio personaje, el cual deberá interpretar durante todo el juego. Para crearlo se deben seguir los siguientes pasos:</p>
        <ol>
            <li>Elegir la profesión del personaje.</li>
            <li>Asignar el temperamento del personaje. </li>
            <li>Asignar las características individuales. </li>
            <li>Asignarle un rol en el equipo. </li>
        </ol>
        <h4>ELECCIÓN DE PROFESIONES</h4>
        <p>Cada personaje debe tener un trasfondo, un pasado que marcará la base de su comportamiento y habilidades durante el juego.</p>
        <p>En Adventurer Mission, los jugadores deben escoger una profesión cercana a su área de estudio y de acuerdo con el test realizado como inicio de la clase.</p>
        <p>Hay Cuatro posibles profesiones a escoger:</p>
        <ol>
            <li><strong>INGENIERO:</strong> Los ingenieros son la fuerza pensante del grupo, son excelentes en lo estratégico, pero les gusta pasar desapercibidos. </li>
            <li><strong>COMERCIALES:</strong> Estos son la fuerza de ataque del grupo, son excelentes en el combate, pero tienen problemas para pasar desapercibidos. Conoce las artes del disfraz, del engaño, experto en trampas y son maestros en las artes de la distracción. </li>
            <li><strong>ABOGADO:</strong> El abogado es el recolector de información por excelencia, experto en seguir rastros y personas, ingresar en edificios vigilados sin ser descubierto y es prácticamente imposible de seguir.</li>
            <li><strong>CONTADOR:</strong> Este es quien maneja las finanzas, los cálculos de tiempo, las estadísticas y el recuento de víveres.</li>
        </ol>
        <h4>ASIGNACIÓN DEL TEMPERAMENTO</h4>
        <p>Dentro del trasfondo del personaje, también debe existir un temperamento que ha marcado su “vida” y determina de alguna manera las habilidades y defectos del presente. Los jugadores, así como en la vida real, no tendrán la oportunidad de elegir el temperamento que definirá a su personaje dentro del juego. Se asignará un temperamento al azar. Uno de los de la mesa se encargará de mezclar después de cada elección.</p>
        <p>Dependiendo del número sacado se asignará alguno de los siguientes caracteres:</p>
        <ol>
            <li><strong>Sanguíneo:</strong> Analítico y calculador, se mantiene apacible incluso en las situaciones de mayor tensión. No sucumbe ante prácticamente ninguna provocación. Nunca se lanzará de primero al combate, prefiere recoger la mayor cantidad de información antes de actuar. </li>
            <li><strong>Colérico:</strong> Impulsivo y muy emocional. Reacciona ante las situaciones sin estrategia previa y suele dejar que sus impulsos marquen la guía de sus acciones. Sucumbe ante la menor provocación y reflexiona después de actuar.</li>
            <li><strong>Flemático:</strong> Frío y calculador. Siempre está esperando la mejor oportunidad para su mayor provecho, poco les importa la vida ajena. Cuando sus habilidades no le son suficientes, engaña, hace trampa y utiliza a cualquiera con tal de lograr sus objetivos.</li>
            <li><strong>Melancólico:</strong> Reflexivo y muy emocional. Sus convicciones son las que marcan su actuar. Respetuoso y apegado a la ley, siempre intentará hacerla cumplir por todos los medios. </li>
        </ol>
        <h4>ASIGNACIÓN DE CARACTERÍSTICAS INDIVIDUALES</h4>
        <p>Las características que se colocarán a continuación son el equivalente a las capacidades físicas del personaje dentro del juego, y serán colocadas por cada participante desde sus características propias aportadas al personaje.</p>
        <p>Y estas serán utilizadas para interactuar con el entorno y con los otros equipos.</p>
    </div>
    <div class="col-12" style="text-align: center;">
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd32bYmlUiBQrqWBMuw02VdlUIU4bDsXaHFy0_-fOR7sHSQQQ/viewform?embedded=true" width="90%" height="800" frameborder="0" marginheight="0" marginwidth="0">Cargando…</iframe>
    </div>
    <div class="col-10" style="margin:3rem 0px 3rem;text-align:center;margin-left:auto;margin-right:auto;">
        <button class="btn-volver" [routerLink]="['/recorrid0']" >
            <h4 style="color:black">CONTINUAR</h4>
         </button>
    </div>

   </div>
