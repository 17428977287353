import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InicioComponent } from './inicio/inicio.component';

import {FormsModule} from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InstruccionesComponent } from './instrucciones/instrucciones.component';
import { JuegoderolesComponent } from './juegoderoles/juegoderoles.component';
import { Recorrid0Component } from './recorrid0/recorrid0.component';


@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    InstruccionesComponent,
    JuegoderolesComponent,
    Recorrid0Component,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
