import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instrucciones',
  templateUrl: './instrucciones.component.html',
  styleUrls: ['./instrucciones.component.css']
})
export class InstruccionesComponent implements OnInit {
  nombregrupo = 'Ngäbe-Buglé';
  nombresimulador = 'Adventurer Mission | ';
  //valor:number;
  valor = 150;
  value: any;
  
  constructor() { }

  ngOnInit() {
  }

}
