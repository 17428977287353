import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-juegoderoles',
  templateUrl: './juegoderoles.component.html',
  styleUrls: ['./juegoderoles.component.css']
})
export class JuegoderolesComponent implements OnInit {
  nombregrupo = 'Ngäbe-Buglé';

  constructor() { }

  ngOnInit() {
  }

}
