import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './inicio/inicio.component';
import { InstruccionesComponent } from './instrucciones/instrucciones.component';
import { JuegoderolesComponent } from './juegoderoles/juegoderoles.component';
import { Recorrid0Component } from './recorrid0/recorrid0.component';


const routes: Routes = [
	{path: '', pathMatch: 'full', redirectTo: '/inicio'},
	{
		path: 'inicio',
    	component: InicioComponent,
	},
	{
		path: 'instrucciones',
    	component: InstruccionesComponent,
	},
	{
		path: 'recorrid0',
    	component: Recorrid0Component,
	},
	{
		path: 'juegoderoles',
    	component: JuegoderolesComponent,
	},


	{path: '**', redirectTo: '/inicio'},
		
];

@NgModule({
	imports: [RouterModule.forRoot(routes,{preloadingStrategy: PreloadAllModules, useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }